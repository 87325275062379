import { Fragment } from "react"
import React from "react"
const Disclaimer= props =>{
return(
    <Fragment>
        <div className={`flex bottom--spacing flex-row ${props.className? props.className:""}`}>
            <span className="color-black disclaimer-text-small mr-1">{`Disclaimer:`}</span><span className="disclaimer-text-small color-grey">{props.text}</span>
        </div>
    </Fragment>
)
}
export default Disclaimer