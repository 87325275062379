import React from "react"
import styled from "styled-components"
import { CHANNEL, LOOKALIKE_AUDIENCE, MEDIUM, UTM_SOURCE } from "../../../../util/eventLogger/segment/constants/eventProperties"
import * as eventLogger from "../../../../util/eventLogger";
import { OVERDRAFT_ELIGIBILITY_INITIATED } from "../../../../util/eventLogger/segment/constants/eventTags";

const ButtonStyled = styled.div`
display: flex;

@media(max-width: 767px){
    justify-content: center;
}
`
const HeaderCTAButton = props => {


    const handleOverdraftInitiatedEvent = () => {

        if (props.employerUrl.includes('overdraft') && props.employerUrl.split('?').length > 1) {
            const queryParameters = new URLSearchParams(window.location.search)
            // const queryParameters = new URLSearchParams(props.employerUrl);

            /**-----------------SEGMENT---------------------------- */
            const segmentData = {
                [LOOKALIKE_AUDIENCE]: queryParameters.get("lookalike_audience"),
                [UTM_SOURCE]: queryParameters.get('utm_source'),
                [MEDIUM]: queryParameters.get('utm_medium'),
                [CHANNEL]: queryParameters.get('utm_campaign')
            };
            eventLogger.pushEventGlobally(OVERDRAFT_ELIGIBILITY_INITIATED, segmentData);
            //     /**---------------------------------------------------------------- */
        }

    }

    return (
        <ButtonStyled className={props.className}>
            <a
                href={props.employerUrl}
                className="kuda-cta kuda-cta-long mt-4  kuda_web"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleOverdraftInitiatedEvent}
            >
                {props.title}
            </a>

        </ButtonStyled>
    )
}
export default HeaderCTAButton 