import React from "react";
import { Component } from "react";

class FaqPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 0
        };
    }

    arrow = ' <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z" fill="#C7C7CC" /</svg>'

    render() {
        const { q, a, activeTab, index, activateTab, pathname } = this.props;
         const isActive = activeTab === index;

        return (
            <div className='panel'
                role='tabpanel'
                aria-expanded={isActive}>
                <div className={`${pathname? 'color-black' : 'color-primary'} panel__label`}
                    role='tab'
                    onClick={activateTab}>
                    <span>
                        {q}
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: this.arrow }}>
                    </span>
                </div>
                <div className='panel__inner'
                    aria-hidden={!isActive}>
                    <div className='panel__content color-black'>
                        {a}
                    </div>
                </div>
            </div>
        );
    }
}

export default React.memo(FaqPanel);