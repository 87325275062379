import React from "react"
import Internet from "../../../components/body/pages/bill-payments/internet"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const InternetPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/bill-payments/internet/"}
      title="Internet Bill Payments | Recharge Easily | Kuda"
      description="The Kuda app makes paying for internet data plans quick & easy. With payments to Spectranet, Smile, 9Mobile, Airtel, Glo & MTN accepted, it's never been easier."
    />
    <Internet />
  </Layout>
)

export default InternetPage
