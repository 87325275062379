import React from "react"
import { Img } from "react-image"
import EntryBadgesV2 from "./badgesV2.js"
import License from "./licenses.js"
import Disclaimer from "./disclaimer.js"
import HeaderCTAButton from './headerCTAButton.js'
const BadgesEntryFold = props => {
  return (
    <div
      className={`kuda-entry--fold ${typeof props.className !== "undefined" ? props.className : ""
        } ${!props.illustration ? "isCentered" : ""}`}
    >
      <div className="kuda-entry--inner flex justify-between align-center">
        <div className="kuda-section--45">
          <div className="kuda-main--wrap text">
            <h1 className={`text-xl kuda-main--heading text-xlbold color-primary title-bottom--spacing ${props.isBusiness ? "text-lg-biz" : ""}`}>
              {props.title}
            </h1>

            <p
              className={`card-main--heading text-semi-bold color-black title-bottom--spacing ${props.isDownload ? "desktop-only" : ""
                } ${props.isBusiness ? "card-main--heading-biz" : ""}`}
            >
              {props.subtitle}
            </p>

            {!props.showEmployerBtn ? <EntryBadgesV2 className="pt-3 title-bottom--spacing" /> : null}
            {props.showEmployerBtn ? <HeaderCTAButton  className="title-bottom--spacing" employerUrl={props.headerLink} title={props.btnTitle}/> : null}

            <License />
            {props.disclaimerText ?
              <Disclaimer text={props.disclaimerText} className="pt-2" /> : null
            }
          </div>
        </div>
        {props.illustration && (
          <div className="kuda-section--55">
            <div className="kuda-illustration--center illustration-image">
              {typeof props.isImage !== "undefined" ? (
                <Img
                  alt="kuda Microfinance Bank Savings, Spend, Budgeting."
                  src={props.illustration}
                  loader={props.fallback}
                />
              ) : (
                props.illustration
              )}
            </div>
          </div>
        )}

      </div>
    </div>
  )
}

export default BadgesEntryFold
