import React, { useEffect, Fragment } from "react";
import CTA from "../general/cta";
import Illustration from "../../../../assets/img/kuda-internet.inline.svg";
import { scrollToElement } from "../../../utility/utils";
import BelowFoldCards from "../general/belowFoldCards";
import BadgesEntryFold from "../general/badgesEntryFold";
import NetworkAntennaIcon from "../../../../assets/img/network-antennas.inline.svg"
import RouterIcon from "../../../../assets/img/baseline-router.inline.svg"
import PhoneIcon from "../../../../assets/img/airtime-icon-blue.inline.svg"
import NetworkProviderImage from "../../../../assets/img/kuda-network-provider.inline.svg"
import MobileDataPlansImage from "../../../../assets/img/spectranet.inline.svg"
import KudaAppSectionImage from "../../../../assets/img/kuda-internet-app.inline.svg"
import ConfirmTransactionImage from "../../../../assets/img/kuda-confirm-transaction.inline.svg"
import ImageTextRight from "../general/imageTextRight";
import ImageTextLeft from "../general/imageTextLeft";
import FAQList from "../general/faqsList";
import MoreForYouComponent from "../general/more-for-you";

import KudaAirtimeIcon from '../../../../assets/img/more-for-you/kuda-airtime-icon.inline.svg'
import TransferIcon from '../../../../assets/img/more-for-you/kuda-transfer.inline.svg'
import BorrowIcon from "../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg"

const entryContent = {
    title: "Buy internet data and subscribe to data plans easily on the Kuda app.",
    subtitle: "Top up your mobile data quickly and get dozens of internet plans for phones, modems and routers any time.",
    illustration: <Illustration/>
}
const kudaTopFeatures = [
    {
        icon: <NetworkAntennaIcon />,
        text: "Buy internet data from major Nigerian internet service providers.",
    },
    {
        icon: <RouterIcon />,
        text: "Subscribe to mobile, modem and router data plans easily.",
    },
    {
        icon: <PhoneIcon />,
        text: "Choose from dozens of internet data plans in seconds.",
    },
]
const networkProviders = {
    title: "Find major Nigerian internet service providers on Kuda.",
    subtitle: "Buy Airtel, Glo, MTN, 9Mobile, Smile and Spectranet data plans directly from your account.",
    url: "/joinKuda/",
    name: "Join Kuda",
    illustration: <NetworkProviderImage />,
}
const kudaApp = {
    title: "The Kuda app comes with your favourite mobile data plans.",
    subtitle: "Low on mobile data? Top up quickly without buying airtime or dialing a data code.",
    url: "https://help.kuda.com/a/solutions/articles/73000560095",
    name: "Learn How To Buy Data",
    illustration: <KudaAppSectionImage />,
}
const monthlyDataPlan = {
    title: "Get monthly 4G and 5G data plans for modems and routers easily.",
    subtitle: "Subscribe to broadband internet for business and streaming any time.",
    url: "https://kuda.onelink.me/abUI/344e3dde",
    name: "Download Kuda",
    illustration: <MobileDataPlansImage />,
}
const sentData = {
    title: "Send internet data to your friends and family.",
    subtitle: "Top up data on phones, modems and routers from your Kuda app even when you’re abroad.",
    url: "/joinKuda/",
    name: "Join Kuda",
    illustration: <ConfirmTransactionImage />,
}

const questions = [
    {
        heading: "What’s an internet data plan?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">An internet data plan is the amount of internet data that you can use on a phone, 
                    modem or router for a specific period of time. When you buy a data plan and the 
                    plan is activated by the internet service provider, you’ll be able to connect to 
                    the internet until the plan expires or your internet data runs out.

                </span>

            </span>),
        index: 1
    },
    {
        heading: "How do I buy internet data on the Kuda app?",
        list: (
            <span className="flex flex-column">
                <span>To buy internet data, follow the steps below:</span>
                <span className="faq-description f-16 pt-3 career-message-margin-bottom ">
                    <ol className="faqs-list-max ordered-list text-left pl-0  leading-md-6 f-md-16">
                        <li className="mb-3">Open your Kuda app, then tap Pay.</li>
                        <li className="mb-3">Tap Pay A Bill.</li>
                        <li className="mb-3">Tap Internet.</li>
                        <li className="mb-3">Tap your mobile network or internet service provider.</li>
                        <li className="mb-3">Choose your internet subscription package (data plan).</li>
                        <li className="mb-3">Type in your phone number or customer ID, then tap Next.</li>
                        <li className="mb-3">Confirm your transaction with your transaction PIN, fingerprint or Face ID.</li>
                        <li className="mb-3">Tap Okay to return to your Kuda dashboard.</li>
                    </ol>
                </span>
            </span>),
        index: 2
    },
    {
        heading: "How much internet data do I need?",
        list: (
            <div className="faq-description f-16 flex flex-column inApp-contact-ways--wrap">
                <div className="mb-3">The amount of internet data you need depends on what you do when you’re online. </div>
                <div className="mb-3">Here are some typical online activities and the amount of data they consume on average:</div>
                <div className="mb-3">Browsing the internet for 1 hour - 60 MB of data</div>
                <div className="mb-3">Streaming music for 1 hour - 72 MB of data</div>
                <div className="mb-3">Streaming a video in standard definition (SD) for 1 hour - 2 GB of data</div>
                <div className="mb-3">Streaming a video in high definition (HD) for 1 hour - 6 GB of data</div>
                <div className="mb-3">To help you choose an internet data plan, do a quick calculation of how much data you’re likely to use in a day.</div>

            </div>),
        index: 3
    },
    {
        heading: "What consumes internet data?",
        list: (
            <div className="faq-description f-16 flex flex-column  inApp-contact-ways--wrap">
                <div className="bottom--spacing">
                    The activities that consume the most internet data are music and video streaming,
                    online gaming, and large file downloads such as films and OS updates.

                </div>
                <div>
                    Automatic updates can also consume a lot of data if you have a lot of apps and you update them on mobile data. To conserve your mobile data,
                    turn off automatic app updates or limit automatic updates to Wifi connections
                </div>

            </div>),
        index: 4
    }
]
const moreForYou = [
    {
        icon: <KudaAirtimeIcon />,
        title: "Airtime",
        subText: `Buy Airtel, Glo, MTN or 9Mobile airtime directly from your account on your Kuda app.`,
        linkTo: `/en-ng/airtime/`
    },
    {
      icon: <TransferIcon />,
      title: "Transfer & Spend",
      subText: "Send money for free to any Nigerian account with 25 free transfers every month.",
      linkTo: `/en-ng/spend/`
    },
    {
      icon: <BorrowIcon />,
      title: "Loans",
      subText: `Get up to ₦150,000 in your Kuda account easily and repay in convenient instalments.`,
      linkTo: `/en-ng/personal-loan/`
  },
  
  ]
const Internet = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <BadgesEntryFold
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                illustration={entryContent.illustration}
            />
            <BelowFoldCards topFeatures={kudaTopFeatures} />
            <ImageTextRight
                title={networkProviders.title}
                subtitle={networkProviders.subtitle}
                illustration={networkProviders.illustration}
                name={networkProviders.name}
                url={networkProviders.url}
            />
            <ImageTextLeft
                title={kudaApp.title}
                subtitle={kudaApp.subtitle}
                illustration={kudaApp.illustration}
                name={kudaApp.name}
                url={kudaApp.url}
            />
            <ImageTextRight
                title={monthlyDataPlan.title}
                subtitle={monthlyDataPlan.subtitle}
                illustration={monthlyDataPlan.illustration}
                name={monthlyDataPlan.name}
                url={monthlyDataPlan.url}
            />
            <ImageTextLeft
                title={sentData.title}
                subtitle={sentData.subtitle}
                illustration={sentData.illustration}
                name={sentData.name}
                url={sentData.url}
            />
            <FAQList title={"Internet Data FAQs"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou}/>
            <CTA />
        </Fragment>
    )
}

export default Internet;
