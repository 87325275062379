import React from "react"
import { Link } from "gatsby"
import ArrowRight from "../../../../assets/img/arrow-right-white.inline.svg"

const EntryBadges = props => {
  if (props.isBusiness) {
    return (
      <a
        href="https://business.kuda.com/get-started/"
        className="kuda-cta kuda-cta-long kuda-cta-biz cta_button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="mr-3">Get an account</span>
        <ArrowRight />
      </a>
    )
  }

  return (
    // !props.hideDownload && (
    <div
      className={`kuda-get--wrap ${
        typeof props.className !== "undefined" ? props.className : ""
      }`}
    >
      {!props.isAmbassador ? (
        <Link to={"/joinKuda/"} className="kuda-cta kuda-cta-long mt-4  kuda_web">
          Join Kuda
        </Link>
      ) : (
        <a
          href={props.ambassadorUrl}
          className="kuda-cta kuda-cta-long mt-4  kuda_web"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join Kuda
        </a>
      )}
    </div>
  )
  // )
}
export default EntryBadges
