import React from "react"
import EntryBadges from "./badges.js"
import Illustration from "../../../../assets/img/global/cta.inline.svg"
import EntryBadgesV2 from "./badgesV2.js"
const CTA = props => {
  return (
    <div className="kuda-section">
      <div className="kuda-entry--inner kuda-cta--section flex justify-between align-center">
        <div className="kuda-section--50">
          <div className="kuda-main--wrap">
            <h3 className="kuda-section--heading text-xlbold color-primary title-bottom--spacing">
              The money app for Africans.
            </h3>
            <p className="color-black bottom--spacing">
              Save, spend, send and invest money across borders.
            </p>
            <EntryBadgesV2
              className="mobile-only bottom--spacing"
              isAmbassador={props.isAmbassador}
              ambassadorUrl={props.ambassadorUrl}
            />

            <EntryBadges
              className="desktop-only footer-global"
              isAmbassador={props.isAmbassador}
              ambassadorUrl={props.ambassadorUrl}
            />
          </div>
        </div>
        <div className="kuda-section--50">
          <div className="cta-illustration">
            <Illustration />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CTA)
