import React, { useState } from "react";
import FaqPanel from "./faqPanel";
import { useLocation } from "@reach/router";
import styled from "styled-components";

const FAQStyledContainer = styled.div`

.faqs-list-title {
  max-width: 400px;
}
.faqs-business-list-title{
  max-width: 500px;
  min-width: 100%;
}

.faqs-list-max {
  max-width: 700px;
}

.accordion {
  max-width: 726px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
}

@media only screen and (max-width: 769px){
  margin-top: 0px !important;
}
`

const FAQList = (props) => {
  const location = useLocation();

  const { questions } = props;
  const [state, setState] = useState({
    activeTab: 0,
  })
  const { activeTab } = state

  const activateTab = index => {
    setState(prevState => ({
      ...prevState,
      activeTab: prevState.activeTab === index ? -1 : index,
    }))
  }
  return (
    <FAQStyledContainer className={`kuda-section--100 kuda-spacing--inner kuda-standard--section ${location?.pathname.includes("/en-ng/business") ? null : "no-top--padding"} `}>
      <div className="kuda-section--heading flex justify-center">
        <h1 className={`${props.maxWidthClass ? props.maxWidthClass : "faqs-list-title"} ${location?.pathname.includes("/en-ng/business") ? 'faqs-business-list-title' : null} text-center text-xlbold ${location?.pathname.includes("/en-ng/business") ? 'color-black' : 'color-primary'} title-bottom--spacing`}>
          {props.title}
        </h1>
      </div>
      <div className="accordion mt-5" role="tablist">
        {questions.map(({ heading, list }, index) => (
          <FaqPanel
            key={index}
            activeTab={activeTab}
            index={index}
            q={heading}
            a={list}
            pathname={location?.pathname.includes("/en-ng/business") ? true : false}
            activateTab={() => activateTab(index)}
          />
        ))}

      </div>
    </FAQStyledContainer>
  )
}
export default React.memo(FAQList)