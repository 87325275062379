import React, { Fragment } from "react";
import { Link } from "gatsby"
import ArrowRight from "../../../../assets/img/arrow-right.inline.svg"

const Goto = (props) => {
    return (
        <Fragment>
            {
                typeof props.onCallback !== "undefined" ?
                    <p onClick={props.onCallback} className={`learn-more color-primary flex align-center ${props.isWebButton? " kuda_web":" cta_button"}`}><span className="learn-more--icon">{props.name}</span><span className="arrow-right--icon"><ArrowRight /></span></p>
                    :
                    typeof props.isExternal !== "undefined" ?
                <a href={props.to} target="_blank" rel="nofollow noopener noreferrer" className={`learn-more color-primary flex align-center ${props.isWebButton? " kuda_web":" cta_button"}`}><span className="learn-more--icon">{props.name}</span><span className="arrow-right--icon"><ArrowRight /></span></a>
                :
                typeof props.isToScroll !== "undefined" ?
                    <p onClick={props.click} className={`learn-more color-primary flex align-center ${props.isWebButton? " kuda_web":" cta_button"}`}><span className="learn-more--icon">{props.name}</span><span className="arrow-right--icon"><ArrowRight /></span></p>
                    :
                    <Link to={props.to} className={`learn-more color-primary flex align-center ${props.isWebButton? " kuda_web":" cta_button"}`}><span className="learn-more--icon">{props.name}</span><span className="arrow-right--icon"><ArrowRight /></span></Link>}
        </Fragment>
    )
}

export default Goto;
