import React from "react";
import Goto from "./goto";
import Disclaimer from "../general/disclaimer";
const ImageTextLeft = (props) => {
    return (
        <div className="kuda-section">
            <div className="kuda-section--inner flex justify-between align-center">
                <div className="kuda-section--45">
                    <div className="kuda-section--wrap">
                        <h2 className="kuda-section--heading text-xlbold color-primary title-bottom--spacing">
                            {props.title}
                        </h2>
                        <p className="color-black kuda-section--max">
                            {props.subtitle}
                        </p>
                        {props.disclamerText? <div className="mt-2"><Disclaimer text={props.disclamerText}/></div>: null}
                        { (props.url && props.name) && <Goto to={props.url} onCallback={props.onCallback} name={props.name} isExternal={props.isExternal} isWebButton={props.isWebButton}/> }
                    </div>
                </div>
                <div className="kuda-section--55">
                    <div className="kuda-section--illustration">
                        {props.illustration}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ImageTextLeft);
